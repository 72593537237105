import React from "react"
import Section from "../components/section"
import { Helmet } from "react-helmet"

import  "../pages/resume.css"
const RESUME_DATA = {
    title: 'Aaron Chu',
    subtitle: 'Senior Software Engineer, Researcher in Disability & Design',
    contact: {
        tel: '646.912.3612',
        email: 'arona.chu [at] gmail.com',
    },
    data: [
        {
            title: 'Experience',
            data: [
                {
                    type: 0,
                    title: '1/2020 &mdash; Present',
                    axTitle: 'January 2020 to Present',
                    location: 'Millersville University, PA',
                    position: 'Adjunct Professor of Design',
                    data: [
                        '<span class="verb">Designed</span> courses that are informed by Disability culture and humanity studies.',
                        '<span class="verb">Lectured</span> using Universal Design Education principles by providing multi-modal access to course content.',
                    ]
                },
                {
                    type: 0,
                    title: '3/2019 &mdash; Present',
                    axTitle: 'March 2019 to Present',
                    location: 'Media Products, Apple Inc., NY',
                    position: 'Senior User Interface Engineer',
                    data: [
                        '<span class="verb">Developed</span> high-traffic interfaces for music.apple.com, tv.apple.com and the Vision Pro Apps & Games store on apps.apple.com.',
                        '<span class="verb">Educated</span> teams on accessiblity through presentations and 1-on-1 consultations.',
                    ]
                },
                {
                    type: 0,
                    title: '2/2016 &mdash; 3/2019',
                    axTitle: 'February 2016 to March 2019',
                    location: 'Accessibility, Apple Inc., CA',
                    position: 'Web User Interface Engineer',
                    data: [
                        '<span class="verb">Designed</span> the Web Inspector Audit Tab by conducting a 15-person qualitative user research using a prototype.',
                        '<span class="verb">Prototyped</span> the Web Inspector Audit Tab using Javascript and C++.',
                        '<span class="verb">Automated</span> Javascript UI library testing with Voice Over by building a self-maintained server using Python, Bash and Javascript.',
                        '<span class="verb">Provided</span> accessibility consultancy across various teams within Apple.',
                        '<span class="verb">Fixed</span> inaccessible native browser media controls in Safari.',
                        '<span class="verb">Fixed</span> accessibility issues and enhanced WebKit Web Inspector.',
                    ]
                },
                {
                    type: 0,
                    title: '7/2014 &mdash; 2/2016',
                    axTitle: 'July 2014 to February 2016',
                    location: 'iCloud, Apple Inc., CA',
                    position: 'Web Developer',
                    data: [
                        '<span class="verb">Developed</span> Web interfaces and email templates for icloud.com.',
                    ]
                },
                {
                    type: 0,
                    title: '10/2011 &mdash; 7/2014',
                    axTitle: 'October 2011 to July 2014',
                    location: 'Huge Inc, NY',
                    position: 'Web Engineer',
                    data: [
                        '<span class="verb">Designed & developed</span> front-end architecture and CMS templates for high-traffic websites.',
                        '<span class="verb">Collaborated</span> with Visual Design and UX teams to scope, design and build digital products that are used by millions.',
                        '<span class="verb">Documented</span> technical details for CMSs such as IBM WebSphere and Sitecore.',
                        '<span class="verb">Mentored</span> junior front-end engineers.',
                    ]
                },
            ]
        },
        {
            title: 'Education',
            data: [
                {
                    type: 1,
                    school: '2023<span class="school">New York University, NY</span>',
                    degree: 'Master of Arts in',
                    major: 'Integrated Design & Media',
                    thesis: 'Thesis: Neurodivergent Web User Experience.',
                },
                {
                    type: 1,
                    school: '2021<span class="school">School of Visual Arts, NY</span>',
                    degree: 'Master of Arts in',
                    major: 'Design Research Writing & Criticism',
                    thesis: 'Thesis: How Accessible Design Decenters Disability.',
                },
                {
                    type: 1,
                    school: '2011<span class="school">Millersville University of Pennsylvania, PA</span>',
                    degree: 'Bachelor of Arts in',
                    major: 'Art and Design',
                }
            ]
        },
        {
            title: 'Relevant Skills & Experience',
            data: [
                {
                    type: 2,
                    data: [
                        'Javascript, HTML, CSS',
                        'Software Accessibility',
                        '3D Printing',
                        'Assistive technologies',
                        'Microcontrollers',
                        'Qualitative research methods',
                        'WebKit contributor',
                        'Board of Trustees, Tech Kids Unlimited',
                    ]
                },
            ]
        },
        {
            title: 'Publications',
            data: [
                {
                    type: 2,
                    data: [
                        '<span class="pub-title">Richard Blake, FNSS: The Medal of Honor Recipient of the National Sculpture Society</span>, SCULPTUREVIEW, July, 2023.',
                        '<span class="pub-title">Understanding Criticism through Design<span>, includr.org, March, 2022.',
                        '<span class="pub-title">‘Inclusive design’ has become so widely used that it’s meaningless. That has to change</span>, Co. Design | Fast Company, November, 2021.',
                    ]
                },
            ]
        }
    ]
}

class ResumeIndex extends React.Component {
  constructor(props) {
    super(props);

    const { title, subtitle, contact, data } = RESUME_DATA;
    this.title = title;
    this.subtitle = subtitle;
    this.contact = contact;

    this.children = data.map((content, idx) => {
        return (
            <Section key={`${content.title}-content-${idx}`} data={content} />
        )
    });
  }

  render() {
    const { title, subtitle, contact, children } = this;
    return (
        <div className="resume">
            <Helmet title="Aaron Chu | Résumé" />
            <h1>{title}</h1>
            <span className="resume-subtitle">{subtitle}</span>
            <div className="resume-contact">
                <span>{contact.tel}</span>
                <span>{contact.email}</span>
            </div>
            <div className="resume-content">
                {children}
            </div>
        </div>
    )
  }
}

export default ResumeIndex